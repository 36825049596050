import React from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import PressDetail from "../../components/pressDetail"

const subTitles = [
  "| 여행과 통신 결합된 서비스 혁신 다짐… 새로운 시장 개척 및 기술개발 공조",
  "| 서로의 강점을 최대한 발휘하고 협력하여 긍정적인 시너지 효과를 창출할 계획",
  "| 핀다이렉트 데이터로밍, 일반 로밍 대비 편리한 UX, 저렴한 비용, 친환경적 요소 등 장점 두루 갖춰",
]

const content = () => (
  <div>
    <br />
    <div className="descImage">
      <div className="img">
        <img src="/images/press/38/38_img1.png" style={{ width: "300px", margin: "auto" }} alt="CI" />
      </div>
      <div className="desc">&lt;(좌)스테이지파이브 CI (우)야놀자 CI&gt;</div>
    </div>
    <p>
      <br />
      <br />
      스테이지파이브(대표 서상원)는 야놀자(총괄대표 이수진)와의 협업을 강화한다고 00일 밝혔다. 이번 협업은 각사의 사업 확대 및
      강화 기조에 따라 통신과 여행을 결합한 서비스를 설계하여 차별화 하는데 방점을 찍는다. 이를 위해 서로의 강점을 최대한 발휘하고
      협력하여 긍정적인 시너지 효과를 창출할 계획이다. 구체적으로, 양 사는 기존 ‘핀다이렉트 로밍’보다 한 단계 나아간 형태의 로밍
      서비스를 기획 및 구축하고 해당 서비스를 연내 출시할 예정에 있다.
      <br />
      <br />
      스테이지파이브는 지난 22년 12월 인터파크트리플과 ‘핀다이렉트 로밍’ 서비스를 런칭한 후 현재까지 누적 조회수 74만 명을 웃돌며
      꾸준한 관심을 받아왔다. 핀다이렉트 로밍은 가입 및 적용 UX 편의성이 압도적이고, 가격도 매우 합리적으로 구성되어 있다. 또한,
      로밍 전문 상담을 24시간 운영하며 고객 입장에서의 서비스를 제공하는 데 집중하였다. 이는 현지 USIM을 구매하는 등 ‘다소
      불편했던 로밍 경험’이나 ‘과도한 로밍 요금 청구’에 대한 소비자들의 우려를 불식시키고 완벽한 여행경험을 제공했기에 좋은 반향을
      얻은 것으로 풀이된다. ‘핀다이렉트 로밍’ 서비스를 사용하면 해외여행 시 통신 3사 대비 매우 합리적인 가격으로 간편하게 데이터
      기반 통신이 가능하다. 특히, eSIM 데이터로밍에 대한 이용자 선호도가 70% 이상으로 확인되었다. 플라스틱 프리의 eSIM 데이터로밍
      상품을 선택하면 번거로운 배송 과정이 생략되고, 다운로드를 통해 바로 사용이 가능하다.
      <br />
      <br />
      스테이지파이브 관계자는 “글로벌 트래블 테크기업인 야놀자와 통신 테크기업인 스테이지파이브 양사 상호 간의 신뢰와 협력을
      바탕으로, 여행자들에게 새로운 통신 편의성과 접근성을 제공할 것”이라고 밝혔다.
      <br />
      <br />
      또한, 양사 간 전략적 파트너십 강화 차원에서 야놀자가 스테이지파이브의 유상증자에 참여했다. 이번 유치 금액을 포함한
      스테이지파이브의 누적 투자 규모는 1,000억 원을 넘어선다.
      <br />
      <br />
      한편, 스테이지파이브는 24시간 로밍전문 상담 시행, 현지망 제휴 확대, AI개통 도입, 빌링내재화 도입 진행 등 통신 관련
      기술혁신을 위한 연구와 투자에 꾸준히 힘쓰고 있다.
      <br />
    </p>
  </div>
)

const press38 = () => (
  <Layout>
    <SEO title="Press" />
    <PressDetail
      dep="(주)스테이지파이브"
      writer="커뮤니케이션실 pr@stagefive.com"
      date="2023년 8월 13일 즉시배포 가능"
      pageInfo=""
      title="스테이지파이브, 야놀자와 협업 강화… ‘데이터 걱정 없이 야놀자!’"
      subTitles={subTitles}
      content={content}
    />
  </Layout>
)

export default press38
